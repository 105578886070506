<template>
  <div>
    <div class="d-flex mb-16">
      <h1 class="headline--md text-bold text-uppercase">Gold investment program</h1>

      <div
        v-if="currentUser && $auth('Page', currentUser, $accessRule.ProgramUpdate).canView()"
        class="d-flex align-start form-switch-wrapper"
      >
        <p class="mr-4">Go live on app:</p>

        <label class="form-switch">
          <input type="checkbox" v-model="program.programStatus" />
          <span></span>
        </label>
      </div>
    </div>

    <div class="box box--lg box--white">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <div class="d-flex">
                  <p class="statistic__title text-warm">Background image</p>
                  <div class="form-upload">
                    <input id="gold-investment-image" type="file" @change="setImageFile($event)" />
                    <label for="gold-investment-image" class="btn--clear link link--primary link--clone">Change</label>
                  </div>
                </div>

                <img
                  ref="imagePreview"
                  class="mt-8"
                  :src="goldInvestmentInfo && goldInvestmentInfo.image_url"
                  alt="gold investment background"
                />
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Min. Withdraw<br />Gold Amount (gram)</p>
                <div class="form-group">
                  <input
                    v-model="$v.settings.minWithdrawGoldAmount.value.$model"
                    :class="[
                      'form-control form-control--md mt-4',
                      { 'form-control--error': $v.settings.minWithdrawGoldAmount.value.$anyError }
                    ]"
                  />
                  <p v-if="$v.settings.minWithdrawGoldAmount.value.$anyError" class="form-error-text">
                    Min withdraw gold amount is invalid
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Fee<br />TOKO</p>
                <div class="form-group">
                  <input
                    v-model="$v.settings.withdrawFee.value.$model"
                    :class="[
                      'form-control form-control--md mt-4',
                      { 'form-control--error': $v.settings.withdrawFee.value.$anyError }
                    ]"
                  />
                  <p v-if="$v.settings.withdrawFee.value.$anyError" class="form-error-text">Withdraw fee is invalid</p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Markup Fee <br />Reduction in %</p>
                <div class="form-group">
                  <input
                    v-model="$v.settings.rateReduce.value.$model"
                    :class="[
                      'form-control form-control--md mt-4',
                      { 'form-control--error': $v.settings.rateReduce.value.$anyError }
                    ]"
                    type="text"
                  />
                  <p v-if="$v.settings.rateReduce.value.$anyError" class="form-error-text">Rate reduction is invalid</p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Markup Fee<br />Reduction in IDR</p>
                <div class="form-group">
                  <input
                    v-model="$v.settings.tokoDepositReduce.value.$model"
                    :class="[
                      'form-control form-control--md mt-4',
                      { 'form-control--error': $v.settings.tokoDepositReduce.value.$anyError }
                    ]"
                  />
                  <p v-if="$v.settings.tokoDepositReduce.value.$anyError" class="form-error-text">
                    TOKO desposit reduction is invalid
                  </p>
                </div>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="box box--white mt-24 pb-0">
      <h2 class="headline--md text-bold mb-16">Requirements of Joining Program</h2>
      <div class="divider"></div>
      <div class="table-wrapper table-wrapper--fluid">
        <table class="table table--white">
          <thead>
            <tr>
              <th>Conditions</th>
              <th>Min. Staked<br />TOKO</th>
              <th>Min. Buying and<br />Investing Gold (USDT)</th>
              <th>Max. Buying and<br />Investing Gold (USDT)</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in $v.requirements.$each.$iter" :key="index">
              <td>Condition {{ parseInt(index) + 1 }}</td>
              <td>
                <div class="form-group">
                  <input
                    v-model="item.minimum_toko_deposited.$model"
                    :class="[
                      'form-control form-control--auto text-center',
                      { 'form-control--error': item.minimum_toko_deposited.$anyError || !item.unique }
                    ]"
                  />
                  <p v-if="item.minimum_toko_deposited.$anyError" class="form-error-text">
                    Minimum toko desposited is invalid
                  </p>
                  <p v-if="!item.unique" class="form-error-text">Requirement data must be unique</p>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    v-model="item.minimum_buying_usdt.$model"
                    :class="[
                      'form-control form-control--auto text-center',
                      { 'form-control--error': item.minimum_buying_usdt.$anyError || !item.unique }
                    ]"
                  />
                  <p v-if="item.minimum_buying_usdt.$anyError" class="form-error-text">
                    Minimum buying usdt is invalid
                  </p>
                  <p v-if="!item.unique" class="form-error-text">Requirement data must be unique</p>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    v-model="item.maximum_buying_usdt.$model"
                    :class="[
                      'form-control form-control--auto text-center',
                      { 'form-control--error': item.maximum_buying_usdt.$anyError || !item.unique }
                    ]"
                  />
                  <p v-if="item.maximum_buying_usdt.$anyError" class="form-error-text">
                    Maximum buying usdt is invalid
                  </p>
                  <p v-if="!item.unique" class="form-error-text">Requirement data must be unique</p>
                </div>
              </td>
              <td>
                <button tabindex="-1" class="btn btn--clear" @click="removeRequirement(item.$model, index)">
                  <toko-trash-icon />
                </button>
              </td>
            </tr>

            <tr>
              <td colspan="5">
                <button class="btn--clear link link--primary link--clone" @click="addDummyRequirement">
                  Add More Row
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="box box--white mt-24 pb-0">
      <h2 class="headline--md text-bold mb-16">Markup Fee</h2>
      <div class="divider"></div>
      <div class="table-wrapper table-wrapper--fluid">
        <table class="table table--white">
          <thead>
            <tr>
              <th>Gold Amount<br />(gram)</th>
              <th>Markup Fee<br />(%)</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in $v.markupFees.$each.$iter" :key="index">
              <td>
                <div class="form-group">
                  <input
                    v-model="item.gold_weight.$model"
                    :class="[
                      'form-control form-control--sm text-center',
                      { 'form-control--error': item.gold_weight.$anyError } || !item.unique
                    ]"
                  />
                  <p v-if="item.gold_weight.$anyError" class="form-error-text">Gold weight is invalid</p>
                  <p v-if="!item.unique" class="form-error-text">Markup fee data must be unique</p>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    v-model="item.fee.$model"
                    :class="[
                      'form-control form-control--sm text-center',
                      { 'form-control--error': item.fee.$anyError || !item.unique }
                    ]"
                  />
                  <p v-if="item.fee.$anyError" class="form-error-text">Fee is invalid</p>
                  <p v-if="!item.unique" class="form-error-text">Markup fee data must be unique</p>
                </div>
              </td>
              <td>
                <button tabindex="-1" class="btn btn--clear" @click="removeMarkupFee(item.$model, index)">
                  <toko-trash-icon />
                </button>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <button @click="addDummyMarkupFee" class="btn--clear link link--primary link--clone">
                  Add More Row
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="text-right mt-24">
      <button @click="openEditConfirmationModal" class="btn btn--primary">Save</button>
    </div>

    <confirm-dialog
      v-if="onConfirmEditProgram"
      :open.sync="onConfirmEditProgram"
      confirmButtonText="Save"
      confirmButtonClass="text-purple"
      @confirm="onUpdate"
    >
      <p>Are you sure to update this program ?</p>
    </confirm-dialog>

    <confirm-dialog
      v-if="onConfirmRemoveRequirement"
      :open.sync="onConfirmRemoveRequirement"
      confirmButtonText="Continue"
      confirmButtonClass="text-purple"
      @confirm="onDeleteGoldInvestmentRequirement"
    >
      <p>Are you sure to remove this requirement data ?</p>
    </confirm-dialog>

    <confirm-dialog
      v-if="onConfirmRemoveMarkupFee"
      :open.sync="onConfirmRemoveMarkupFee"
      confirmButtonText="Continue"
      confirmButtonClass="text-purple"
      @confirm="onDeleteGoldInvestmentMarkupFee"
    >
      <p>Are you sure to remove this markup fee data ?</p>
    </confirm-dialog>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { GOLD_INVESTMENT_SETTINGS } from '@/utils/key-mapping'
import { deepCopy, isObjEqual } from '@/utils/json-parser'
import goldInvestmentFormValidator from '@/validations/gold-investment-form'
import toggleLoading from '@/mixins/toggle-loading'
import formatActivate from '@/mixins/format-activate'
import updateProgram from './mixins/update-program'
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const Loading = () => import('@/components/Loading')
const TokoTrashIcon = () => import('@/ui/toko-icons/TokoTrash')

export default {
  data() {
    return {
      program: {
        programStatus: true,
        programImage: null
      },
      goldInvestmentSettingKeys: GOLD_INVESTMENT_SETTINGS,
      onConfirmEditProgram: false,
      onConfirmRemoveRequirement: false,
      onConfirmRemoveMarkupFee: false,
      settings: {
        minWithdrawGoldAmount: {
          value: 0
        },
        withdrawFee: {
          value: 0
        },
        rateReduce: {
          value: 0
        },
        tokoDepositReduce: {
          value: 0
        }
      },
      requirements: [],
      markupFees: [],
      dummyMarkupFee: {
        gold_weight: 0,
        fee: 0
      },
      dummyRequirement: {
        minimum_toko_deposited: 0,
        minimum_buying_usdt: 0,
        maximum_buying_usdt: 0
      },
      requirementTargetId: null,
      markupFeeTargetId: null
    }
  },

  mixins: [updateProgram, formatActivate, toggleLoading, goldInvestmentFormValidator],

  computed: {
    ...mapState('goldInvestment', [
      'goldInvestmentInfo',
      'goldInvestmentSettings',
      'goldInvestmentRequirements',
      'goldInvestmentMarkupFees'
    ])
  },

  components: {
    ConfirmDialog,
    Loading,
    TokoTrashIcon
  },

  methods: {
    ...mapActions('goldInvestment', [
      'getGoldInvestmentSettings',
      'getGoldInvestmentRequirements',
      'getGoldInvestmentMarkupFees',
      'addGoldInvestmentRequirement',
      'updateGoldInvestmentRequirement',
      'deleteGoldInvestmentRequirement',
      'addGoldInvestmentMarkupFee',
      'updateGoldInvestmentMarkupFee',
      'deleteGoldInvestmentMarkupFee'
    ]),

    // =================== Gold Investment Program Data ================== //

    setImageFile(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const imgEl = this.$refs.imagePreview
      const srcImg = URL.createObjectURL(files[0])
      // preview image
      imgEl.src = srcImg
      // set image file
      this.programImage = files[0]
    },

    getProgramDataUpdate() {
      return {
        id: this.goldInvestmentInfo.id,
        status: this.programStatus ? this.status.deactive : this.status.active,
        file: this.programImage
      }
    },

    // ====================== Gold Investment Requirement ================== //

    async addOrEditGoldInvestmentRequirement() {
      if (!this.requirements.length || isObjEqual(this.requirements, this.goldInvestmentRequirements)) return

      for (let i = 0; i <= this.requirements.length; i++) {
        if (!isObjEqual(this.requirements[i], this.goldInvestmentRequirements[i])) {
          this.requirements[i].id
            ? await this.updateGoldInvestmentRequirement(
                this.getGoldInvestmentRequirementDataRequest(this.requirements[i])
              )
            : await this.addGoldInvestmentRequirement(
                this.getGoldInvestmentRequirementDataRequest(this.requirements[i])
              )
        }
      }
    },

    getGoldInvestmentRequirementDataRequest(item) {
      return {
        ...item,
        minimum_toko_deposited: parseFloat(item.minimum_toko_deposited),
        minimum_buying_usdt: parseFloat(item.minimum_buying_usdt),
        maximum_buying_usdt: parseFloat(item.maximum_buying_usdt)
      }
    },

    onDeleteGoldInvestmentRequirement() {
      this.toggleLoading()
      this.deleteGoldInvestmentRequirement(parseInt(this.requirementTargetId))
        .then(() => {
          this.initGoldInvestmentRequirements()
        })
        .finally(() => {
          this.onConfirmRemoveRequirement = false
          this.toggleLoading()
        })
    },

    addDummyRequirement() {
      this.requirements.push(deepCopy(this.dummyRequirement))
    },

    removeRequirement(item, index) {
      if (item.id) {
        this.requirementTargetId = item.id
        this.onConfirmRemoveRequirement = true
      } else {
        this.requirements.splice(index, 1)
      }
    },

    // ====================== Gold Investment Markup Fee ================== //

    addDummyMarkupFee() {
      this.markupFees.push(deepCopy(this.dummyMarkupFee))
    },

    onDeleteGoldInvestmentMarkupFee() {
      this.toggleLoading()
      this.deleteGoldInvestmentMarkupFee(parseInt(this.markupFeeTargetId))
        .then(() => {
          this.initGoldInvestmentMarkupFees()
        })
        .finally(() => {
          this.onConfirmRemoveMarkupFee = false
          this.toggleLoading()
        })
    },

    removeMarkupFee(item, index) {
      if (item.id) {
        this.markupFeeTargetId = item.id
        this.onConfirmRemoveMarkupFee = true
      } else {
        this.markupFees.splice(index, 1)
      }
    },

    getGoldInvestmentMarkupFeeDataRequest(item) {
      return {
        ...item,
        gold_weight: parseFloat(item.gold_weight),
        fee: parseFloat(item.fee)
      }
    },

    async addOrEditGoldInvestmentMarkupFee() {
      if (!this.markupFees.length || isObjEqual(this.markupFees, this.goldInvestmentMarkupFees)) return

      for (let i = 0; i <= this.markupFees.length; i++) {
        if (!isObjEqual(this.markupFees[i], this.goldInvestmentMarkupFees[i])) {
          this.markupFees[i].id
            ? await this.updateGoldInvestmentMarkupFee(this.getGoldInvestmentMarkupFeeDataRequest(this.markupFees[i]))
            : await this.addGoldInvestmentMarkupFee(this.getGoldInvestmentMarkupFeeDataRequest(this.markupFees[i]))
        }
      }
    },

    // ========================== Init Data ============================== //

    initGoldInvestmentRequirements() {
      this.requirements = this.goldInvestmentRequirements ? deepCopy(this.goldInvestmentRequirements) : []
    },

    initGoldInvestmentMarkupFees() {
      this.markupFees = this.goldInvestmentMarkupFees ? deepCopy(this.goldInvestmentMarkupFees) : []
    },

    initGoldInvestmentSettings() {
      const settingsData = deepCopy(this.goldInvestmentSettings)
      for (let i = 0; i < settingsData.length; i++) {
        switch (settingsData[i].key) {
          case this.goldInvestmentSettingKeys.tokoDepositReduce:
            this.settings.tokoDepositReduce = settingsData[i]
            break
          case this.goldInvestmentSettingKeys.rateReduce:
            this.settings.rateReduce = settingsData[i]
            break
          case this.goldInvestmentSettingKeys.minWithdrawGoldAmount:
            this.settings.minWithdrawGoldAmount = settingsData[i]
            break
          case this.goldInvestmentSettingKeys.withdrawFee:
            this.settings.withdrawFee = settingsData[i]
            break
        }
      }
    },

    // ====================== Gold Investment Total Data ================== //

    openEditConfirmationModal() {
      this.$v.$touch()
      if (!this.isFormValid) return

      this.onConfirmEditProgram = true
    },

    async onUpdate() {
      this.toggleLoading()
      try {
        await this.updateProgram(this.getProgramDataUpdate())
        await this.addOrEditGoldInvestmentRequirement()
        await this.addOrEditGoldInvestmentMarkupFee()

        // update programStatus
        this.programStatus = this.goldInvestmentInfo.status === this.status.active
      } catch (e) {
        this.onConfirmEditProgram = false
        this.toggleLoading()
        throw e
      }

      this.onConfirmEditProgram = false
      this.toggleLoading()
    }
  },

  async created() {
    try {
      await this.getProgramList()
      await this.getGoldInvestmentSettings()
      await this.getGoldInvestmentMarkupFees({ order_by: 'asc' })
      await this.getGoldInvestmentRequirements({ order_by: 'asc' })
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.programStatus = this.goldInvestmentInfo.status === this.status.active

    this.initGoldInvestmentRequirements()
    this.initGoldInvestmentMarkupFees()
    this.initGoldInvestmentSettings()
    this.toggleLoading()
  }
}
</script>
