import { required, numeric, decimal } from 'vuelidate/lib/validators'
import { rate, greaterOrEqualZero, greaterThanZero } from './utils'

export default {
  computed: {
    isFormValid() {
      return (
        !this.$v.requirements.$anyError &&
        !this.$v.markupFees.$anyError &&
        !this.$v.settings.$anyError &&
        this.requirementUnique &&
        this.markupFeeUnique
      )
    },

    requirementUnique() {
      let unique = true
      if (this.$v.requirements.$each.$iter) {
        Object.keys(this.$v.requirements.$each.$iter).forEach(key => {
          if (!this.$v.requirements.$each.$iter[key].unique) unique = false
        })
      }

      return unique
    },

    markupFeeUnique() {
      let unique = true
      if (this.$v.markupFees.$each.$iter) {
        Object.keys(this.$v.markupFees.$each.$iter).forEach(key => {
          if (!this.$v.markupFees.$each.$iter[key].unique) unique = false
        })
      }

      return unique
    }
  },

  validations: {
    settings: {
      minWithdrawGoldAmount: {
        value: { required, numeric, greaterOrEqualZero }
      },
      withdrawFee: {
        value: { required, numeric, greaterOrEqualZero }
      },
      rateReduce: {
        value: { required, decimal, rate }
      },
      tokoDepositReduce: {
        value: { required, numeric, greaterOrEqualZero }
      }
    },

    requirements: {
      $each: {
        unique(item, requirements) {
          const filter = requirements.filter(
            requirement =>
              parseFloat(requirement.minimum_toko_deposited) === parseFloat(item.minimum_toko_deposited) &&
              parseFloat(requirement.minimum_buying_usdt) === parseFloat(item.minimum_buying_usdt) &&
              parseFloat(requirement.maximum_buying_usdt) === parseFloat(item.maximum_buying_usdt)
          )

          return filter.length <= 1
        },
        minimum_toko_deposited: { required, numeric, greaterOrEqualZero },
        minimum_buying_usdt: { required, numeric, greaterThanZero },
        maximum_buying_usdt: {
          greaterOrEqualMinBuy: (value, vm) => value >= vm.minimum_buying_usdt
        }
      }
    },

    markupFees: {
      $each: {
        unique(item, markupFees) {
          const filter = markupFees.filter(
            markupFee =>
              parseFloat(markupFee.gold_weight) === parseFloat(item.gold_weight) &&
              parseFloat(markupFee.fee) === parseFloat(item.fee)
          )

          return filter.length <= 1
        },
        gold_weight: { required, numeric, greaterThanZero },
        fee: { required, decimal, rate }
      }
    }
  }
}
